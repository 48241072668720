import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 960.000000 960.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,960.000000) scale(0.100000,-0.100000)">
		<path d="M5915 7035 c-30 -30 -32 -64 -4 -99 l20 -26 -82 -158 c-45 -86 -94
-179 -109 -207 -15 -27 -48 -92 -74 -142 -26 -51 -49 -93 -51 -93 -1 0 -32 11
-67 24 -110 42 -191 47 -701 45 -263 -1 -476 -4 -473 -6 2 -2 37 -9 78 -14
342 -48 596 -183 717 -383 75 -125 108 -246 118 -426 14 -288 -60 -512 -224
-672 -71 -70 -113 -99 -224 -154 -207 -104 -441 -144 -835 -144 l-224 0 2
-572 c2 -545 2 -556 7 -215 4 261 9 360 18 365 6 4 14 30 16 57 2 28 2 8 0
-42 -3 -65 -1 -93 7 -93 7 0 10 -15 9 -40 -2 -22 1 -44 7 -50 7 -7 347 -9
1039 -8 1019 3 1028 3 1032 23 3 11 5 36 6 56 1 25 5 33 12 26 6 -6 9 -23 7
-39 -3 -16 0 -28 7 -28 6 0 11 12 11 27 0 14 3 23 7 19 9 -9 5 -72 -7 -106 -4
-14 -11 -34 -15 -45 -5 -16 -8 -17 -16 -5 -9 12 -10 12 -5 -3 3 -10 6 -31 6
-45 0 -24 2 -23 19 14 51 111 50 107 51 1044 1 858 1 876 -20 949 -11 40 -29
90 -39 110 l-19 37 45 49 c25 28 125 133 222 235 97 101 190 200 208 218 30
32 33 33 67 21 30 -11 38 -10 61 5 50 33 55 100 9 130 -58 38 -128 -3 -122
-72 l3 -37 -185 -117 c-400 -252 -431 -271 -441 -261 -6 6 0 47 16 114 14 57
50 209 81 338 30 129 60 243 65 253 5 10 20 18 32 18 12 0 27 4 33 8 16 11 34
48 34 72 0 32 -44 70 -80 70 -19 0 -40 -9 -55 -25z"/>
<path d="M3760 5496 l0 -356 208 0 c114 0 236 5 271 10 213 30 313 139 313
340 0 89 -23 171 -60 216 -37 44 -116 94 -180 115 -59 19 -96 22 -309 27
l-243 4 0 -356z"/>
<path d="M3807 3923 c3 -87 7 -160 9 -162 2 -2 4 68 4 157 0 91 -4 162 -9 162
-6 0 -7 -65 -4 -157z"/>
<path d="M5940 3985 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5898 3814 c3 -15 8 -22 14 -16 9 9 1 42 -12 42 -4 0 -5 -12 -2 -26z"/>
<path d="M5866 3744 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z"/>
<path d="M5772 3639 c-19 -30 -8 -34 12 -4 9 14 13 25 9 25 -5 0 -14 -9 -21
-21z"/>
<path d="M3245 3006 c-61 -26 -89 -108 -60 -170 26 -54 63 -76 127 -76 74 0
95 8 101 36 6 32 -5 37 -39 19 -40 -21 -77 -19 -108 6 -37 29 -37 99 0 136 22
21 32 25 67 20 23 -2 50 -8 60 -12 13 -6 17 -2 17 18 0 17 -7 26 -22 31 -38
10 -111 6 -143 -8z"/>
<path d="M4855 3012 c-3 -3 -5 -25 -5 -49 l0 -43 -55 0 -55 0 0 46 c0 45 0 45
-32 42 l-32 -3 2 -118 c1 -65 4 -122 6 -126 3 -5 16 -8 28 -7 21 1 23 7 26 59
l3 57 54 0 54 0 3 -57 c3 -56 4 -57 33 -58 l30 0 3 126 3 127 -31 4 c-16 3
-32 2 -35 0z"/>
<path d="M5655 3006 c-84 -37 -97 -159 -24 -221 25 -20 41 -25 87 -25 85 0
102 7 102 41 0 34 1 33 -36 14 -72 -37 -137 -1 -137 75 0 72 62 107 136 76
l37 -15 0 28 c0 21 -6 30 -22 35 -38 10 -111 6 -143 -8z"/>
<path d="M5884 2986 c-37 -32 -39 -36 -39 -95 0 -54 3 -64 31 -91 38 -38 96
-54 144 -38 50 15 76 43 89 93 23 92 -38 165 -136 165 -42 0 -56 -5 -89 -34z
m141 -31 c19 -18 25 -35 25 -65 0 -96 -110 -125 -140 -36 -14 44 -4 81 27 106
34 27 59 25 88 -5z"/>
<path d="M6932 3000 c-67 -41 -81 -146 -25 -201 28 -29 109 -53 142 -42 72 22
71 22 71 84 l0 59 -50 0 c-47 0 -50 -2 -50 -25 0 -18 5 -25 20 -25 24 0 28
-35 4 -44 -29 -11 -53 -6 -81 18 -40 35 -39 98 1 133 30 25 52 26 124 7 18 -5
22 -2 22 19 0 29 -20 37 -95 37 -33 0 -61 -7 -83 -20z"/>
<path d="M2490 2882 l0 -128 30 -2 30 -2 0 60 c0 56 1 60 24 60 60 0 88 13
102 47 27 64 -9 93 -115 93 l-71 0 0 -128z m114 82 c9 -3 16 -15 16 -25 0 -15
-7 -19 -35 -19 -31 0 -35 3 -35 25 0 26 19 32 54 19z"/>
<path d="M2720 2883 l0 -128 123 0 122 1 13 27 c12 24 19 27 63 27 43 0 51 -3
60 -24 11 -24 57 -41 71 -27 4 4 -11 54 -33 111 -22 58 -43 113 -46 123 -4 14
-14 17 -46 15 l-41 -3 -50 -114 c-28 -62 -52 -109 -54 -102 -2 6 -28 11 -63
11 l-59 0 0 35 0 35 55 0 c52 0 55 1 55 25 0 24 -3 25 -55 25 -52 0 -55 1 -55
25 0 24 3 25 60 25 53 0 60 2 60 20 0 19 -7 20 -90 20 l-90 0 0 -127z m348 10
c12 -36 8 -43 -24 -43 -32 0 -37 14 -17 54 18 39 25 38 41 -11z"/>
<path d="M3450 2881 l0 -128 88 1 c82 1 87 2 90 24 3 21 0 22 -57 22 l-61 0 0
35 0 35 56 0 c52 0 55 1 52 23 -3 19 -10 22 -55 25 -49 3 -53 5 -53 28 0 23 3
24 60 24 53 0 60 2 60 20 0 19 -7 20 -90 20 l-90 0 0 -129z"/>
<path d="M3662 2883 l3 -127 83 1 c87 2 121 17 149 66 18 31 16 94 -3 127 -28
46 -63 60 -153 60 l-82 0 3 -127z m167 62 c10 -10 16 -33 16 -61 0 -56 -28
-84 -84 -84 l-36 0 -2 73 c-2 39 -1 78 2 86 7 18 82 9 104 -14z"/>
<path d="M4017 3003 c-2 -4 -26 -59 -52 -121 -50 -119 -50 -141 -2 -128 15 3
27 15 31 31 6 23 11 25 61 25 47 0 55 -3 61 -22 4 -13 12 -26 18 -30 14 -10
56 -10 56 0 0 4 -20 61 -44 127 l-43 120 -41 3 c-22 2 -42 -1 -45 -5z m58 -99
c8 -20 15 -40 15 -45 0 -10 -53 -12 -62 -3 -6 5 21 84 28 84 2 0 11 -16 19
-36z"/>
<path d="M4160 2990 c0 -17 7 -20 45 -20 l45 0 0 -109 0 -108 30 1 30 1 0 108
0 107 45 0 c38 0 45 3 45 20 0 19 -7 20 -120 20 -113 0 -120 -1 -120 -20z"/>
<path d="M4432 2890 c-29 -66 -51 -124 -48 -129 5 -7 29 -8 57 -2 4 0 10 12
13 26 6 25 10 26 64 23 50 -3 57 -6 60 -25 2 -13 6 -23 10 -24 43 -5 62 -3 62
7 0 7 -20 63 -44 125 l-44 114 -38 3 -39 3 -53 -121z m106 11 c16 -51 16 -51
-16 -51 -38 0 -43 7 -26 38 8 15 14 33 14 40 0 26 17 10 28 -27z"/>
<path d="M4970 2914 c0 -92 1 -97 29 -125 26 -26 36 -29 90 -29 49 0 65 4 88
24 27 23 28 29 31 125 l4 101 -31 0 -31 0 0 -83 c0 -96 -14 -127 -60 -127 -46
0 -60 31 -60 127 l0 83 -30 0 -30 0 0 -96z"/>
<path d="M5252 2883 l3 -128 85 2 c79 2 87 4 108 29 27 30 25 79 -4 101 -12 9
-13 16 -5 32 16 29 14 48 -9 71 -17 17 -33 20 -100 20 l-81 0 3 -127z m128 72
c17 -20 4 -35 -31 -35 -24 0 -29 4 -29 25 0 20 5 25 24 25 13 0 29 -7 36 -15z
m15 -120 c0 -27 -3 -30 -37 -33 -38 -3 -38 -3 -38 33 0 36 0 36 38 33 34 -3
37 -6 37 -33z"/>
<path d="M6160 2881 l0 -130 28 3 c27 3 27 4 30 80 2 42 6 76 10 76 4 0 20
-19 36 -42 l29 -41 38 43 38 43 3 -78 3 -79 30 -1 30 0 3 128 3 127 -32 0
c-25 0 -40 -10 -72 -46 l-40 -46 -39 46 c-30 35 -46 46 -69 46 l-29 0 0 -129z"/>
<path d="M6590 2882 l0 -129 30 -1 30 -1 0 82 1 82 26 -30 c82 -96 127 -136
147 -133 20 3 21 8 21 128 l-1 125 -32 3 -32 3 0 -73 -1 -73 -67 73 c-52 56
-73 72 -94 72 l-28 0 0 -128z"/>
<path d="M5500 2805 c-17 -21 -5 -45 24 -45 27 0 42 26 25 46 -15 18 -33 18
-49 -1z"/>
<path d="M6480 2806 c-18 -22 -5 -46 25 -46 23 0 26 4 23 27 -3 32 -29 41 -48
19z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
